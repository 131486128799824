import { translatedField } from "../util"

export const renderFilters = (jobFiltersSections, handleClick, selectedFilters) => {
  const filterContainer = document.querySelector('.joblist-filters__list');

  if (filterContainer) {
    const orderedSections = jobFiltersSections.sort((a, b) => a.sortingValue - b.sortingValue);

    orderedSections.forEach((filterSection) => {
      let sectionContainer;

      if (filterSection.type === 'country') {
        sectionContainer = filterContainer.querySelector('.joblist-filters__list-filterbox--country');
      } else if (filterSection.type === 'jobType') {
        sectionContainer = filterContainer.querySelector('.joblist-filters__list-filterbox--jobType');
      } else if (filterSection.type === 'category') {
        sectionContainer = filterContainer.querySelector('.joblist-filters__list-filterbox--category');
      }

      if (sectionContainer && filterSection.filters) {
        const dropdownContent = sectionContainer.querySelector('.dropdown__content');

        const filters = filterSection.filters
            .sort((a, b) => {
              if (filterSection.type === 'category') {
                const filterNameA = translatedField(a.name)
                const filterNameB = translatedField(b.name)
                return filterNameA.localeCompare(filterNameB)
              }
              return a.sortingValue - b.sortingValue
            })
            .map((filter) => {
              const isChecked = selectedFilters?.includes(`${filterSection.type}:${filter.code}`)
              const filterName = translatedField(filter.name)

          return `<div class="joblist-filters__list-filter">
            <label class="checkbox">
              <input type="checkbox" data-filterSection="${filterSection.type}" name="${filter.code}" ${isChecked ? 'checked' : ''} />
              <span class="checkbox__box"></span>
              <span class="checkbox__label">${filterName} (${filter.count})</span>
            </label>
          </div>`;
        });

        dropdownContent.innerHTML = filters.join('');
      }
    });

    const checkboxes = filterContainer.querySelectorAll('.joblist-filters__list-filter input');
    if (checkboxes.length) {
      checkboxes.forEach((checkbox) => checkbox.addEventListener('click', handleClick));
    }
  }
};

document.addEventListener('DOMContentLoaded', function() {
  const dropdownTriggers = document.querySelectorAll('.dropdown__trigger');

  dropdownTriggers.forEach(trigger => {
    trigger.addEventListener('click', function(event) {
      event.stopPropagation();

      const dropdownContent = trigger.nextElementSibling;

      if (trigger.classList.contains('active')) {
        trigger.classList.remove('active');
        dropdownContent.style.maxHeight = null;
      } else {
        trigger.classList.add('active');
        dropdownContent.style.maxHeight = dropdownContent.scrollHeight + 'px';
      }
    });
  });
});